import { FC } from 'react'
import { NotFound } from '@lifedot/components/NotFound'
import { CommonContentsDummy } from '@/contents/CommonContentsDummy'
import { useHandleFallbackQuery } from '@lifedot/hooks/use-handle-fallback-query'

const NotFoundPage: FC = () => {
  const handled = useHandleFallbackQuery(true)
  if (!handled) return <CommonContentsDummy />
  return <NotFound />
}

export default NotFoundPage
